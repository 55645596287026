import React, {useContext, useEffect, useState} from 'react';
import DispatchContext from "../../DispatchContext";
import {useLocation, useNavigate} from "react-router";
import * as c from "../../constants";
import ServerService from "../../API/ServerService";
import axios from "axios";
import StateContext from "../../StateContext";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
//import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import UsersChangePassword from "../Users/UserChangePassword";
import xbot_logo from "../../../img/logo.webp"

const pages = [
    {url: "cp/users", roles_arr: [c.USER_ROLES.ADMIN], title: "Users"},
    {url: "cp/devices", roles_arr: [c.USER_ROLES.ADMIN, c.USER_ROLES.OWNER], title: "Devices"},
    {url: "cp/coupons", roles_arr: [c.USER_ROLES.ADMIN, c.USER_ROLES.OWNER], title: "Coupons"},
    {url: "cp/discounts", roles_arr: [c.USER_ROLES.ADMIN, c.USER_ROLES.OWNER], title: "Discounts"}
];

const AppHeader = () => {
    const [logout, setLogout] = useState(false);
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(null);
    const dispatch = useContext(DispatchContext);
    const state = useContext(StateContext);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        let axios_controller = new AbortController();
        if (state.user) {
            dispatch({type: c.SET_LOADING_PAGE, payload: true});
            ServerService.getUserInfo(state.user.user_id, axios_controller).then((response) => {
                //console.log(response.user);
                if (!response.user) {
                    setLogout(true);
                }
                dispatch({type: c.SET_USER, payload: response.user});
            }).catch((err) => {
                if (axios.isCancel(err)) {
                    return;
                }
                //console.log("null user");
                dispatch({type: c.SET_USER, payload: null});
                console.error(`API ERR ${err}`);
            }).finally(() => {
                dispatch({type: c.SET_LOADING_PAGE, payload: false});
            });
        } else {
            if (location.pathname !== '/login' && location.pathname !== '/cp/login') {
                navigate("/cp/login");
            }
            setLogout(false);
            return;
        }
        return (() => {
            axios_controller.abort();
        });
    }, []);

    useEffect(() => {
        if (!state.user)
            return;
        let axios_controller = new AbortController();
        let simple_poll_interval = setInterval(() => {
            ServerService.simplePoll(axios_controller).then((response) => {
                if (!response.is_token_valid)
                    navigate("/cp/login");
            }).catch((err) => {
                console.error(`API ERR ${err}`);
            })
        }, 60 * 1000);
        return (() => {
            clearInterval(simple_poll_interval);
            axios_controller.abort();
        });
    }, [state.user]);

    useEffect(() => {
        if (!logout)
            return;
        const axios_controller = new AbortController();
        dispatch({type: c.SET_LOADING_PAGE, payload: true});
        ServerService.logout(axios_controller).then((response) => {

        }).catch((err) => {
            if (axios.isCancel(err)) {
                return;
            }
            console.error(`API ERR ${err}`);
        }).finally(() => {
            dispatch({type: c.SET_LOADING_PAGE, payload: false});
            dispatch({type: c.SET_USER, payload: null});
            setLogout(false);
            navigate("/cp/login");
        });

        return (() => {
            axios_controller.abort();
        });

    }, [logout]);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleLogoutUserMenu = () => {
        setLogout(true);
        handleCloseUserMenu();
    };

    const onMenuLinkClick = (page) => {
        handleCloseNavMenu();
        navigate(page);
    };

    const handleChangePasswordUserMenu = () => {
        setChangePasswordModalOpen(state.user);
        handleCloseUserMenu();
    };

    if (!state.user)
        return null;
    return (
        <AppBar position="static" sx={{marginBottom: "1rem", backgroundColor: "rgb(86, 94, 103)"}}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Box component="img" src={xbot_logo} alt={"logo"} sx={{maxHeight: "2.5rem"}}/>
                    {/*<AdbIcon sx={{display: {xs: 'none', md: 'flex'}, mr: 1}}/>*/}
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/cp"
                        sx={{
                            mr: 2,
                            display: {xs: 'none', md: 'flex'},
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.1rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        Xbot
                    </Typography>

                    <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: {xs: 'block', md: 'none'},
                            }}
                        >
                            {pages.filter(page => page.roles_arr.includes(state.user.user_role_id)).map((page) => (
                                <MenuItem key={page.url} onClick={() => onMenuLinkClick(page.url)}>
                                    <Typography textAlign="center">{page.title}</Typography>
                                </MenuItem>))}
                        </Menu>
                    </Box>
                    <AdbIcon sx={{display: {xs: 'flex', md: 'none'}, mr: 1}}/>
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="#app-bar-with-responsive-menu"
                        sx={{
                            mr: 2,
                            display: {xs: 'flex', md: 'none'},
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        Xbot
                    </Typography>
                    <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                        {pages.filter(page => page.roles_arr.includes(state.user.user_role_id)).map((page) => (
                            <Button
                                key={page.url}
                                onClick={() => onMenuLinkClick(page.url)}
                                sx={{my: 2, color: 'white', display: 'block'}}
                            >
                                {page.title}
                            </Button>
                        ))}
                    </Box>

                    <Box sx={{flexGrow: 0}}>
                        <Tooltip title="Open settings">
                            <Button size="small" variant="outlined" sx={{color: "white", borderColor: "white"}}
                                    onClick={handleOpenUserMenu}>
                                {state.user && state.user.name ? state.user.name.toUpperCase().slice(0,10) : "User"}
                            </Button>
                        </Tooltip>
                        <Menu
                            sx={{mt: '45px'}}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <MenuItem key={"Logout"} onClick={handleLogoutUserMenu}>
                                <Typography textAlign="center">{"Logout"}</Typography>
                            </MenuItem>
                            <MenuItem key={"Change_password"} onClick={handleChangePasswordUserMenu}>
                                <Typography textAlign="center">{"Change password"}</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
            <UsersChangePassword user={changePasswordModalOpen} onClose={() => setChangePasswordModalOpen(null)}/>
        </AppBar>
    );
};

export default AppHeader;
